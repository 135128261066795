fetchOverdueUserList
<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="用户姓名"
          v-model="query.realName"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportOverdueDevice" v-auth="this.per.EXPORT_USER_OVERDUE"
          >导出逾期信息</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="昵称" prop="userRealName"></el-table-column>
      <el-table-column label="电话" prop="phone"></el-table-column>
      <el-table-column label="借出站点" prop="stationMacId"></el-table-column>
      <el-table-column label="电池编号" prop="batteryMacId"></el-table-column>
      <el-table-column label="到期时间" prop="endTime">
      </el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import {
  fetchOverdueUserList,
  sendTimeoutMsg,
  exportOverdueDevice,
} from "@/api/batteryMan";
import moment from "moment";
import { mapState } from "vuex";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      moment: moment,
      dataSource: [],
      modalData: {},
      pages: {
        pageSize: 10,
      },
      query: {
       
      },

      total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    doExportOverdueDevice() {
      let startTime = "2021-01-01 01:01:01";
      let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

      let exportQuery = {
        startTime: startTime,
        endTime: endTime,
      };

      exportOverdueDevice(exportQuery).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `逾期信息.xlsx`
        );
      });
    },
    sendMsg(row) {
      this.$confirm("是否要发送逾期短信", "提示", {
        type: "warning",
      }).then((res) => {
        sendTimeoutMsg({
          userId: row.user.id,
          type: 1,
          msg: `${row.macid}`,
        }).then((res) => {
          this.$message.success("操作成功！");
        });
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchOverdueUserList({ ...this.query, ...this.pages }).then(
        (res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource = list;
        }
      );
    },
  },
};
</script>
